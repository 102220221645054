import "./App.scss";
import { useEffect, useState } from "react";
import vidaaxtvoe from "./images/tvoexvidaa.png";

import Success from "./components/Success";
import NotHisense from "./components/NotHisense";
import UnableToInstall from "./components/UnableToInstall";
import FooterContent from "./components/FooterContent";
import DownloadButton from "./components/DownloadButton";

function App() {
	const [downloadStatus, setDownloadStatus] = useState(null);

	useEffect(() => {
		if (typeof window.Hisense_GetCountryCode === "function") {
			if (
				typeof Hisense_installApp === "function" || // 5+
				typeof window.HiBrowser != "undefined" // < 4
			) {
				setDownloadStatus("Ready");
			} else {
				setDownloadStatus("Impossible");
			}
		} else {
			setDownloadStatus("NotHisense");
		}
	}, []);

	const onClick = () => {
		const preview = "https://app.tvoe.live/img/touch-logo.jpg";

		if (window?.Hisense_installApp) {
			window?.Hisense_installApp(
				"TVOЁ_debug",
				"TVOЁ",
				preview,
				preview,
				preview,
				"https://app.tvoe.live?device=smart-tv&installed=vidaa",
				"store",
				function () {
					setDownloadStatus("Success");
					// Тригерится когда реально обновляет файловую систему
				}
			);
			setDownloadStatus("Success");
		}

		if (window.HiBrowser !== undefined) {
			var objH = window.getHisenseObject();
			objH.loadLibrary("libhspdk-jsx.so");

			var mydate = new Date();
			var realMonth = mydate.getMonth() + 1;
			var ret = false;
			var newApp = {
				AppId: "TVOЁ_debug",
				Thumb: preview,
				Icon_96: preview,
				Image: preview,
				URL: "https://app.tvoe.live?device=smart-tv&installed=vidaa",
				AppName: "TVOЁ",
				Title: "TVOЁ",
				IconURL: preview,
				StartCommand: "https://app.tvoe.live?device=smart-tv&installed=vidaa",
				InstallTime:
					mydate.getFullYear() + "-" + realMonth + "-" + mydate.getDate(),
				RunTimes: 0,
				StoreType: "store",
				PreInstall: false,
			};

			var installAppObj = window.getInstalledAppJsonObj();
			var presetAppObj = window.getpresetedAppJsonObj();
			var installed = 0;

			function checkArrayItemExist(array, item) {
				var count;
				for (count = 0; count < array.length; count++) {
					if (
						item.AppName.toLowerCase() == array[count].AppName.toLowerCase() ||
						item.URL == array[count].StartCommand
					) {
						break;
					}
				}
				if (count == array.length) {
					return 0;
				} else {
					return 1;
				}
			}

			if (presetAppObj) {
				installed = checkArrayItemExist(presetAppObj.AppInfo, newApp);
			}
			if (installAppObj && !installed) {
				if (!checkArrayItemExist(installAppObj.AppInfo, newApp)) {
					installAppObj.AppInfo.push(newApp);
					ret = true;
				}
			} else if (!installAppObj && !installed) {
				var newAppJsonStr = '{"AppInfo":[' + JSON.stringify(newApp) + "]}";
				installAppObj = eval("(" + newAppJsonStr + ")");
				ret = true;
			}

			if (ret) {
				var writedata = JSON.stringify(installAppObj);
				objH.File.write("launcher/Appinfo.json", writedata, 1);
				setDownloadStatus("Success");
			}
		}
	};

	const downloadState = {
		Ready: <DownloadButton onClick={onClick} />,
		Success: <Success />,
		NotHisense: <UnableToInstall />,
		Impossible: <NotHisense />,
	};

	return (
		<div className="flex flex-col h-screen">
			<header className="flex justify-center items-center my-6 h-20">
				<img src={vidaaxtvoe} alt={"TVOЁ"} width={220} />
			</header>

			<div className="flex flex-col justify-center items-center gap-4 h-full">
				<div className="flex justify-center items-center">
					{downloadState[downloadStatus]}
				</div>
			</div>

			<FooterContent />
		</div>
	);
}

export default App;
