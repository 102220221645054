import QrCode from "../images/qrCode.png";

const NotHisense = () => {
	return (
		<div className="flex items-center justify-center flex-col">
			<div>Невозможно установить приложение.</div>
			<div>Отсканируй QR-код, чтобы обратиться в поддержку</div>
			<img src={QrCode} alt={"TVOЁ"} width={140} />
		</div>
	);
};

export default NotHisense;
