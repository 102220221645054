import cross from "../images/cross.png";

const UnableToInstall = () => {
	return (
		<div className="flex items-center justify-center flex-col">
			<img className={"mb-5"} src={cross} alt={"TVOЁ"} width={80} />

			<div className="max-w-lg text-center">
				Чтобы установить приложение, необходимо открыть эту страницу в браузере
				телевизора на операционной системе VIDAA
			</div>
		</div>
	);
};

export default UnableToInstall;
