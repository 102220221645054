import correct from "../images/correct.png";

const Success = () => {
	return (
		<div className="flex items-center justify-center flex-col">
			<img className={"mb-5"} src={correct} alt={"TVOЁ"} width={80} />

			<div className="max-w-lg text-center">Приложение установлено.</div>
			<div className="max-w-lg text-center">
				Для корректной работы приложения необходимо перезагрузить телевизор:
				выключить и включить
			</div>
		</div>
	);
};

export default Success;
