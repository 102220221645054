const FooterContent = () => {
	return (
		<div className="flex justify-center">
			<div className="flex py-5 px-8">
				<div className="flex justify-center items-center opacity-30">
					<div className="w-9 h-9 border rounded-lg py-1 px-2">18+</div>
					<div className="pl-4 w-44 flex-col flex justify-center">
						<p className="text-xs">2024, ООО «ТВОЁ ЛАЙВ»</p>
						<p className="text-xs">Все права защищены.</p>
					</div>
				</div>
				<div className="w-full max-w-screen-xl flex justify-center items-center">
					<p className="opacity-30 text-xs leading-3">
						Запрещено для детей. Информация на сайте предназначена для людей
						старше 18 лет. Законные представители несовершеннолетних
						пользователей обязаны запретить несовершеннолетним просматривать
						информацию на сайте, за исключением случаев допуска к просмотру
						информации, маркированной знаком 12+ и/или при включении функции
						«Детский режим». В том и в другом случае, использование ресурса
						возможно только при контроле законных представителей
						несовершеннолетних.
					</p>
				</div>
			</div>
		</div>
	);
};

export default FooterContent;
