const DownloadButton = ({ text, onClick }) => {
	return (
		<button
			onClick={() => onClick()}
			className="outline-none text-xl font-medium px-9 py-4 rounded-2xl p-2 hover:opacity-80 active:opacity-60 transition-opacity bg-gradient-to-r from-gradient1 to-gradient2 text-black"
		>
			Установить приложение
		</button>
	);
};

export default DownloadButton;
